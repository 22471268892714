import React from "react";

import Layout from "layout/index";

import SEO from "components/seo/index";

import SpeciesDisplay from "components/species/speciesDisplay";

const Species = () => {
  return (
    <>
      <SEO title="Forests By Heartfulness | Species" />
      <Layout>
        <SpeciesDisplay />
      </Layout>
    </>
  );
};

export default Species;
