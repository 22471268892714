import React, { useEffect, useState } from "react";
import "assets/styles/pages/species.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useQueryParam, StringParam } from "use-query-params";
import DonationService from "services/donations/donation.service";
import { toast, ToastContainer } from "react-toastify";

const SpeciesDisplay = () => {
  const [paramValue] = useQueryParam("slug", StringParam);
  const [speciesDetails, setSpeciesDetails] = useState();
  const [isImage, setIsImage] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);

  const searchSpecies = async (paramValue) => {
    try {
      const cs = new DonationService();
      const cl = await cs.getSpeciesDetails(paramValue);
      if (cl?.data?.speciesImageUrls?.length >= 1) {
        setIsImage(true);
        if (cl?.data?.speciesImageUrls?.length === 1) {
          setShowCarousel(false);
        }
      }
      setSpeciesDetails(cl);
    } catch (err) {
      if (err.message === "Request failed with status code 404") {
        toast.error("Requested Species does not exist!");
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (paramValue !== undefined) {
      searchSpecies(paramValue);
    }
  }, [paramValue]);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      // partialVisibilityGutter: 130,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <span onClick={() => next()}>Previous</span>
          <span onClick={() => previous()} className="me-3">
            Next
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="species-display-wrapper">
        {speciesDetails !== undefined && (
          <div className="details-wrapper row">
            {isImage && (
              <>
                {showCarousel ? (
                  <div className="carousel-wrapper col-md-4">
                    <Carousel
                      arrows={false}
                      draggable
                      infinite
                      keyBoardControl
                      minimumTouchDrag={80}
                      partialVisible
                      renderButtonGroupOutside={true}
                      swipeable
                      responsive={responsive}
                      customButtonGroup={<ButtonGroup />}
                      showDots={true}
                    >
                      {speciesDetails?.data?.speciesImageUrls?.map((file) => {
                        return (
                          <div className="img-wrapper" id="plantList">
                            <img
                              src={file}
                              className="lazyload"
                              alt="path tree"
                              height="150px"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                ) : (
                  <div className="single-img-wrapper col-md-4">
                    <div className="img-wrapper" id="plantList">
                      <img
                        src={speciesDetails?.data?.speciesImageUrls?.[0]}
                        className="lazyload"
                        alt="path tree"
                        height="150px"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={`content-wrapper col-md-${!isImage ? 12 : 8}`}>
              <h5>Species Details</h5>
              <div className="species-desc row">
              {/*<div className="species-name col-md-4 col-sm-12">
                  <p className="details-title">
                    <b>Species Name</b>
                  </p>
                  <p>{speciesDetails?.data?.speciesName}</p>
                </div>*/}
                <div className="species-detail  col-md-4 col-sm-12">
                  <p className="details-title">
                    <b>Common Name</b>
                  </p>
                  <p>{speciesDetails?.data?.commonName}</p>
                </div>
                
                <div className="species-detail  col-md-4 col-sm-12">
                  <p className="details-title">
                    <b>Scientific Name</b>
                  </p>
                  <p>{speciesDetails?.data?.scientificName}</p>
                </div>
                
              </div>
              <div className="habitat-details">
                <div>
                  <p className="details-title">
                    <b>Natural Habitat</b>
                  </p>
                  <p className="details-para">
                    {speciesDetails?.data?.naturalHabitat}
                  </p>
                </div>

                <div>
                  <p className="details-title">
                    <b>Common Uses</b>
                  </p>
                  <p className="details-para">
                    {speciesDetails?.data?.commonUses}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
};

export default SpeciesDisplay;
